import React from "react";
import { Helmet } from "react-helmet";

const MetaTitle = ({ title }) => (
  <Helmet>
    <title>{title ? `${title  } - Cart2Cart` : "Cart2Cart"}</title>
  </Helmet>
);

export default MetaTitle;
