export const getAuthPublicLinks = (publicUrl) => [
  {
    title: "Home",
    link: `${publicUrl}`,
  },
  {
    title: "Supported Carts",
    link: `${publicUrl}/supported-carts`,
  },
  {
    title: "Services",
    link: `${publicUrl}/provided-services`,
  },
  {
    title: "Contact Us",
    link: `${publicUrl}/contact-us`,
  },
];
