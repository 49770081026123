import React, { memo, useState, useEffect, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router";
import {
  registrationQuizTypeArr,
  registrationQuizTypeElem,
  roleTypeIndexMap,
} from "../../constants";
import { useSetBusinessRoleMutation } from "../../../../store/authentication/authService";
import { useFormTo } from "../../../../hooks/useFormTo";
import MetaTitle from "../../../../components/MetaTitle";
import TicketsContainer from "../../../../components/TicketsContainer";
import RegistrationQuizByType from "../RegistrationQuizByType";
import { setRightRoute } from "../../../../utils/providers";
import { USER_PATH } from "../../../../router/path/user";
import { ADMIN_PATH } from "../../../../router/path/admin";
import { useAuth } from "../../../../hooks/useAuth";

import { Container, Wrapper } from "./styles";

const RegistrationQuizMain = ({ modalType, close }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { prefix, route } = useFormTo();
  const [setBusinessRole] = useSetBusinessRoleMutation();
  const [currentType, setCurrentType] = useState(registrationQuizTypeArr[0]);

  const formState = useForm({
    mode: "all",
    defaultValues: {},
  });

  const watch = useWatch({ control: formState.control });

  const handleNextQuiz = (selectedRole) => {
    const nextTypeIndex = roleTypeIndexMap[selectedRole] || null;

    if (nextTypeIndex !== null) {
      setCurrentType(registrationQuizTypeArr[nextTypeIndex]);
    }
  };

  const getRedirect = useCallback(() => {
    const finalPath = !user?.has_migrations
      ? USER_PATH.MI_START_MIGRATION_PAGE
      : ADMIN_PATH.MIGRATIONS;

    return setRightRoute(prefix, route) || finalPath;
  }, [user]);

  const handleFinishQuiz = async (selectedRole) => {
    try {
      await setBusinessRole({
        business_role: selectedRole,
      }).unwrap();

      if (modalType) {
        close(false);
      } else {
        const getPath = getRedirect();
        const path = getPath.startsWith("/") ? `${getPath}` : `/${getPath}`;
        navigate(path);
      }
    } catch (error) {
      const errors = error?.data?.error?.details?.business_role;

      formState.setError(`business`, {
        type: "custom",
        message: errors,
      });
    }
  };

  const handleFormSubmit = () => {
    if (currentType === "startRegistrationQuiz") {
      handleNextQuiz(watch.startRegistrationQuiz);
    } else {
      handleFinishQuiz(watch[currentType]);
    }
  };

  useEffect(() => {
    if (modalType) {
      setCurrentType(modalType);
    }
    if (watch) {
      handleFormSubmit();
    }
  }, [watch]);

  return (
    <Container>
      <MetaTitle title="What's your role?" />
      <Wrapper>
        <TicketsContainer
          data={registrationQuizTypeElem[currentType]}
          modalType={modalType}
        >
          <RegistrationQuizByType
            type={currentType}
            formState={formState}
            data={registrationQuizTypeElem}
            modalType={modalType}
            errors={formState?.formState?.errors?.business?.message}
          />
        </TicketsContainer>
      </Wrapper>
    </Container>
  );
};

export default memo(RegistrationQuizMain);
