import React, { Suspense, useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";

import Footer from "../../Footer";
import Header from "../../Header";
import { getScrollPage } from "../../../utils";
import { ReactComponent as ExpandFalse } from "../../../assets/images/expandFalse.svg";
import { ReactComponent as ExpandTrue } from "../../../assets/images/expandTrue.svg";
import { useAdminPermission } from "../../../hooks/useAdminPermission";
import { useAuth } from "../../../hooks/useAuth";

import { StyledMain, StyledContainer, Wrapper, StyledButton } from "./styles";

const LazyLayout = ({ offLayout }) => {
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const { user } = useAuth();
  const layoutRef = useRef(null);
  const isAdmin = useAdminPermission();

  useEffect(() => {
    getScrollPage(layoutRef, setHiddenMenu);
  }, []);

  const toggleMenuVisibility = () => {
    setIsMenuVisible((prev) => !prev);
  };

  return (
    <>
      {!offLayout && (
        <>
          {process.env.REACT_APP_VISIBLE_HEADER ? (
            <Wrapper>
              {isMenuVisible && <Header hiddenMenu={hiddenMenu} />}
              <StyledButton onClick={toggleMenuVisibility}>
                {isMenuVisible ? (
                  <>
                    <ExpandTrue />
                    Hide Additional Navigation
                  </>
                ) : (
                  <>
                    <ExpandFalse />
                    Show Additional Navigation
                  </>
                )}
              </StyledButton>
            </Wrapper>
          ) : (
            <Header hiddenMenu={hiddenMenu} />
          )}

          <StyledContainer
            ref={layoutRef}
            isMenuVisible={isMenuVisible}
            isAdmin={isAdmin}
            user={user?.id}
            isDisabledAuth={process.env.REACT_APP_VISIBLE_HEADER}
          >
            <StyledMain>
              <Suspense fallback={<h1>Loading ...</h1>}>
                <Outlet />
              </Suspense>
            </StyledMain>

            <Footer />
          </StyledContainer>
        </>
      )}

      {offLayout && (
        <Suspense fallback={<h1>Loading ...</h1>}>
          <Outlet />
        </Suspense>
      )}
    </>
  );
};

export default LazyLayout;
