import React from "react";
import { Collapse } from "antd";
import { openChat } from "../../../utils/providers";
import { Container, StyledLink, StyledButton } from "./styles";

const handleClickButton = () => {
  openChat();
};

export const getHeaderAccordionItems = (items, onClick) =>
  items?.map((item) => ({
    key: item.title,
    label: item.title,
    children: item?.items?.map((link) => (
      <>
        {link?.label?.props?.to === "chat" ? (
          <StyledButton onClick={handleClickButton}>
            {link?.label?.props?.children?.props?.children}
          </StyledButton>
        ) : (
          <StyledLink {...link?.label?.props} key={link?.key} onClick={onClick}>
            {link?.label?.props?.children?.props?.children}
          </StyledLink>
        )}
      </>
    )),
  }));

const HeaderAccordion = ({ items, onClick }) => (
  <Container>
    {items?.map((item) => {
      if (item?.items?.length > 1) {
        return (
          <Collapse
            accordion
            items={getHeaderAccordionItems([item], onClick)}
            key={item.title}
          />
        );
      }

      return (
        <StyledLink {...item} key={item?.key} onClick={onClick} accordion>
          {item?.title}
        </StyledLink>
      );
    })}
  </Container>
);

export default HeaderAccordion;
