import { BaseQueryParams } from "../baseQuery";

export const authService = BaseQueryParams("authentication", [
  "USER",
]).injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        credentials: "include",
        method: "POST",
        body: data,
      }),

      invalidatesTags: () => ["USER"],
    }),

    setNewPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/reset-password",
        method: "PUT",
        body,
      }),
    }),

    socialLogin: builder.mutation({
      query: ({ social }) => ({
        url: `/auth/social-login/${social}`,
        method: "POST",
        credentials: "include",
      }),
    }),

    socialLoginCallback: builder.mutation({
      query: ({ social, code }) => ({
        url: `/auth/social-login/${social}/callback`,
        method: "POST",
        body: { code },
        credentials: "include",
      }),

      invalidatesTags: () => ["USER"],
    }),

    logout: builder.mutation({
      query: (data) => ({
        url: "/auth/logout",
        method: "POST",
        body: data,
      }),
    }),

    simpleRegister: builder.mutation({
      query: (data) => ({
        url: "/auth/simple-register",
        method: "POST",
        body: data,
        credentials: "include",
      }),

      invalidatesTags: () => ["USER"],
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: data,
      }),
    }),

    getUser: builder.query({
      query: () => ({
        url: "/profile",
        method: "GET",
      }),

      providesTags: () => ["USER"],
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: "/profile/password",
        method: "PATCH",
        body: data,
      }),
    }),

    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/profile",
        method: "PUT",
        body: data,
      }),

      invalidatesTags: () => ["USER"],
    }),

    createSubscribe: builder.mutation({
      query: () => ({
        url: "/profile/subscribe",
        method: "POST",
      }),

      invalidatesTags: () => ["USER"],
    }),

    deleteSubscribe: builder.mutation({
      query: () => ({
        url: "/profile/subscribe",
        method: "Delete",
      }),

      invalidatesTags: () => ["USER"],
    }),

    loginAsUser: builder.mutation({
      query: ({ user_id }) => ({
        url: `/admin/users/${user_id}/login`,
        method: "POST",
      }),

      invalidatesTags: () => ["USER"],
    }),

    addAvatar: builder.mutation({
      query: (body) => ({
        url: "/profile/avatar",
        method: "POST",
        body,
      }),
    }),

    deleteAvatar: builder.mutation({
      query: () => ({
        url: "/profile/avatar",
        method: "Delete",
      }),
    }),

    setBusinessRole: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "PATCH",
        body,
      }),
    }),

    getAfterLogin: builder.query({
      query: (params) => ({
        url: "/modules/wix/get-after-login-path",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useSimpleRegisterMutation,
  useForgotPasswordMutation,
  useSocialLoginMutation,
  useSocialLoginCallbackMutation,
  useGetUserQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useSetNewPasswordMutation,
  useCreateSubscribeMutation,
  useDeleteSubscribeMutation,
  useLoginAsUserMutation,
  useAddAvatarMutation,
  useDeleteAvatarMutation,
  useSetBusinessRoleMutation,
  useLazyGetAfterLoginQuery
} = authService;

export const selectToken = (state) => state.auth.access_token;
export const selectUser = (state) => state.auth.user;
export const selectCurrentUser = (state) => state.auth;
export const selectProfileLoading = (state) => state.auth.isLoading;
