import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { CookiesProvider } from "react-cookie";

import { store, persistor } from "./store";
import { GlobalStyles } from "./theme/globalStyles";
import GlobalConfig from "./modules/GlobalConfig";

import reportWebVitals from "./reportWebVitals";
import SessionProvider from "./providers/SessionProvider";
import SettingsProvider from "./providers/SettingsProvider";
import GtmProvider from "./providers/GtmProvider";
import LoadingModalProvider from "./providers/LoadingModalProvider";
import AffiliateProvider from "./providers/AffiliateProvider";
import ThemeProvider from "./providers/ThemeProvider";
import GrowthBookApp from "./providers/GrowthBookProvider";
import ErrorProvider from "./providers/ErrorProvider";
import HelpCrunchProvider from "./providers/HelpCrunchProvider";
import MetaPixel from "./utils/meta/MetaPixel";
import AmplitudeProvider from "./providers/AmplitudeProvider";
import AppRouter from "./router";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
       <ErrorProvider>
        <MetaPixel />
        <PersistGate persistor={persistor}>
          <AmplitudeProvider>
            <GtmProvider>
              <ThemeProvider>
                <GlobalStyles />
                <GlobalConfig />

                <SettingsProvider>
                  <SessionProvider>
                    <HelpCrunchProvider>
                      <GrowthBookApp>
                        <LoadingModalProvider>
                          <CookiesProvider>
                            <AffiliateProvider>
                              <AppRouter />
                            </AffiliateProvider>
                          </CookiesProvider>
                        </LoadingModalProvider>
                      </GrowthBookApp>
                    </HelpCrunchProvider>
                  </SessionProvider>
                </SettingsProvider>
              </ThemeProvider>
            </GtmProvider>
          </AmplitudeProvider>
        </PersistGate>
       </ErrorProvider>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
