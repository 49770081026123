import { BaseQueryParams } from "../baseQuery";

export const profileService = BaseQueryParams("user", [
  "PROFILE",
  "PROFILE_BILLING",
]).injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: "/profile",
        method: "GET",
      }),

      providesTags: () => ["PROFILE"],
    }),

    getUserBalanceHistory: builder.query({
      query: ({ page, positive_only }) => ({
        url: `/profile/balance-history${page ? `?page=${page}` : ""}${
          positive_only ? `&positive_only=${positive_only ? 1 : 0}` : ""
        }`,
        method: "GET",
      }),

      providesTags: () => ["PROFILE_BILLING"],
    }),

    createNewFund: builder.mutation({
      query: (body) => ({
        url: "/payments/process-payment",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PROFILE_BILLING", "PROFILE"],
    }),

    createNewShopifyIpn: builder.mutation({
      query: (body) => ({
        url: "/payments/shopify-ipn",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PROFILE_BILLING"],
    }),

    paymentFormClose: builder.mutation({
      query: (body) => ({
        url: "/payments/payment-form-close",
        method: "PUT",
        body,
      }),
    }),

    createInvoice: builder.mutation({
      query: (body) => ({
        url: "/profile/invoice",
        method: "POST",
        body,
      }),
    }),

    createTicket: builder.mutation({
      query: (body) => ({
        url: "/profile/ticket",
        method: "POST",
        body,
      }),
    }),

    getLoyaltyProgram: builder.query({
      query: () => ({
        url: "/profile/loyalty",
        method: "GET",
      }),
    }),

    downloadInvoice: builder.mutation({
      query: (id) => ({
        url: `/profile/invoice/${id}/pdf`,
        method: "POST",
      }),
    }),

    sendComplain: builder.mutation({
      query: (body) => ({
        url: "/profile/complain",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserBalanceHistoryQuery,
  useCreateNewFundMutation,
  useCreateNewShopifyIpnMutation,
  usePaymentFormCloseMutation,
  useCreateInvoiceMutation,
  useCreateTicketMutation,
  useGetLoyaltyProgramQuery,
  useDownloadInvoiceMutation,
  useSendComplainMutation,
} = profileService;

export const selectUser = (state) => state.profile.user;
export const selectAbilities = (state) => state.profile.abilities;
export const selectInvoice = (state) => state.profile.invoice;
