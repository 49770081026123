import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DisabledInput = styled.input`
  display: none;
`;

export const Title = styled.div`
  width: 100%;
  min-height: 400px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 767px) {
    min-height: auto;
  }
`;
