import React from "react";
import { useSelector } from "react-redux";
import { StyledUl, StyledLi, Link } from "./styles";
import { selectSettings } from "../../../store/settings/settingsSlice";
import { getAuthPublicLinks } from "../../../constants/header";

const HeaderNavigation = () => {
  const { publicUrl } = useSelector(selectSettings);
  const authPublicLinks = getAuthPublicLinks(publicUrl);

  return (
    <StyledUl>
      {authPublicLinks.map(({ title, link }) => (
        <StyledLi key={title}>
          <Link href={link} target="_blank">
            {title}
          </Link>
        </StyledLi>
      ))}
    </StyledUl>
  );
};

export default HeaderNavigation;
