import { styled } from "styled-components";
import { Button } from "antd";

export const StyledMain = styled.main`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1710px) {
    max-width: calc(100% - 226px);
  }

  @media (max-width: 1590px) {
    max-width: calc(100% - 50px);
  }

  @media (max-width: 1435px) {
    zoom: 0.95;
  }

  @media (max-width: 1395px) {
    zoom: 0.9;
  }

  @media (max-width: 1320px) {
    zoom: 1;
    max-width: 1200px;
  }
`;

export const StyledContainer = styled.main`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  height: ${({ isMenuVisible, isAdmin, user, isDisabledAuth }) => {
    const baseHeight = isAdmin || !user ? "109px" : "64px";
    if (isDisabledAuth && !isMenuVisible) {
      return "100%";
    }
    return `calc(100% - ${baseHeight})`;
  }};
  overflow-y: auto;

  @media (max-width: 900px) {
    height: calc(100% - 120px);
    padding: 0 20px;
  }

  @media (max-width: 650px) {
    padding: 0 10px;
  }
`;

export const Wrapper = styled.div``;

export const StyledButton = styled(Button)`
  position: absolute;
  z-index: 99;
  right: 0;
  font-weight: 500;
  background: ${({ theme }) => theme.pallette.blue.main};
  color: ${({ theme }) => theme.pallette.white.main};
  border: none;
  border-radius: 0 0 6px 6px;
  margin-right: 10px;
  transition: none;

  svg {
    margin-right: 10px;
    path {
      stroke: ${({ theme }) => theme.pallette.white.main};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light}!important;
    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.blue.light}!important;
      }
    }
  }
`;
