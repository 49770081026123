import React from "react";
import { Link } from "react-router-dom";

const getProfileLink = (dataArray, prefix) =>
  dataArray.map((item) => ({
    key: item.key,
    label: (
      <Link className="headerLink" to={`${prefix}${item.to}`}>
        <span>{item.title}</span>
      </Link>
    ),
  }));

export const profileLinksArr = [
  {
    key: "1",
    title: "My Profile",
    to: "/profile",
  },
  {
    key: "2",
    title: "My Payments",
    to: "/profile#balance-history",
  },
  {
    key: "3",
    title: "Add Funds",
    to: "/profile#billing",
  },
  {
    key: "4",
    title: "My Migrations List",
    to: "/migrations/list",
  },
  {
    key: "5",
    title: "Generate Invoice",
    to: "/profile#generate-invoice",
  },
  // {
  //   key: "6",
  //   title: "Notifications & Alerts",
  //   to: "/",
  // },
  {
    key: "7",
    title: "Help & Support",
    to: "chat",
  },
  {
    key: "8",
    title: "Quick Create Ticket",
    to: "/profile#ticket",
  },
];

const adminLink = (prefix) => [
  {
    title: "Profile",
    items: getProfileLink(profileLinksArr, prefix),
  },
];

export const getProfileLinks = (prefix) => adminLink(prefix);
