import { createSlice } from "@reduxjs/toolkit";
import { getPublickUrlByHost } from "../../utils";
import { settingsService } from "./settingsService";

const initialState = {
  theme: "main",
  env: null,
  l18n: {},
  options: null,
  publicUrl: "",
  wixGetParam: "",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,

  reducers: {
    setNewTheme: (state, { payload }) => {
      state.theme = payload;
    },

    setWixGetParam: (state, { payload }) => {
      state.wixGetParam = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      settingsService.endpoints.getConfig.matchFulfilled,
      (state, { payload }) => {
        state.env = payload?.payload?.env;
        state.l18n = payload?.payload?.l18n;
        state.options = payload?.payload?.options;
        state.wizard = payload?.payload?.wizard;

        localStorage.setItem("isConfig", "true");

        if (payload?.payload?.env?.hostEnv === "develop") {
          state.theme = "main";
        }

        if (payload?.payload?.env?.publicUrl) {
          state.publicUrl = payload?.payload?.env?.publicUrl;
        } else {
          state.publicUrl = "https://www.shopping-cart-migration.com";
        }
      },
    );
  },
});

export default settingsSlice.reducer;

export const { setNewTheme, setWixGetParam } = settingsSlice.actions;

export const selectSettings = (state) => state.settings;
export const selectWixGetParam = (state) => state.settings.wixGetParam;
export const selectTheme = (state) => state.settings;
