/* eslint-disable no-undef */
import { AUTH_PATH } from "../router/path/auth";
import { WIZARD_STEP_PATH } from "../router/path/wizard";

export const setRightRoute = (prefix, route) => {
  const authRoutes = Object.values(AUTH_PATH).flatMap((item) => [
    `/${item}`,
    `/${item}/`,
  ]);
  const wizardRoutes = Object.values(WIZARD_STEP_PATH).flatMap((item) => [
    `/wizard/${item}`,
    `/wizard/${item}/`,
  ]);

  if (
    authRoutes.includes(route) ||
    wizardRoutes.includes(route) ||
    route === `/${prefix}` ||
    route === `/${prefix}/`
  ) {
    return "";
  }

  return route || "";
};
export const openChat = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('openChat');
  }
};

export const openChatWithMessage = (message) => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('closeChat');
    setTimeout(() => {
      HelpCrunch('typeUserMessage', message);
      HelpCrunch('openChat');
    }, 300);
  }
};

export const closeChat = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch("closeChat");
  }
};

export const hideChatWidget = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('hideChatWidget');
  }
};
