import { Link } from "react-router-dom";

export const ICONS_LINK = {
  FACEBOOK_LINK: "https://www.facebook.com/Cart2Cart",
  TWITTER_LINK: "https://twitter.com/Cart2CartBlog",
  YOUTUBE_LINK: "https://www.youtube.com/Cart2CartVideo",
};

export const informationLink = (publicUrl) => [
  {
    key: "1",
    label: (
      <a
        href={`${publicUrl}/about-us`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>About Us</span>
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        href={`${publicUrl}/provided-services`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Provided Services</span>
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        href={`${publicUrl}/blog`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Blog</span>
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        href={`${publicUrl}/release-notes`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Release Notes</span>
      </a>
    ),
  },
  {
    key: "5",
    label: (
      <a
        href={`${publicUrl}/terms-of-service`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Terms of Service</span>
      </a>
    ),
  },
  {
    key: "6",
    label: (
      <a
        href={`${publicUrl}/service-level-agreement`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Service Level Agreement</span>
      </a>
    ),
  },
  {
    key: "7",
    label: (
      <a
        href={`${publicUrl}/privacy-policy`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Privacy Policy</span>
      </a>
    ),
  },
  {
    key: "8",
    label: (
      <a
        href={`${publicUrl}/sitemap`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Sitemap</span>
      </a>
    ),
  },
  {
    key: "9",
    label: (
      <a
        href={`${publicUrl}/contact-us`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Contact Us</span>
      </a>
    ),
  },
  {
    key: "10",
    label: (
      <a
        href={`${publicUrl}/customer-testimonials`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Testimonials</span>
      </a>
    ),
  },
];

export const forPartnerLink = (publicUrl) => [
  {
    key: "1",
    label: (
      <a
        href={`${publicUrl}/loyalty-program`}
        target="_blank"
        className="footerLink"
        rel="noreferrer"
      >
        <span>Loyalty Program</span>
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        href={`${publicUrl}/migration-service-partners`}
        target="_blank"
        className="footerLink"
        rel="noreferrer"
      >
        <span>Migration Partners</span>
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        href={`${publicUrl}/cart2cart-press-kit`}
        target="_blank"
        className="footerLink"
        rel="noreferrer"
      >
        <span>Cart2Cart Press Kit</span>
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        href="https://www.api2cart.com"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
      >
        <span>eCommerce API</span>
      </a>
    ),
  },
];

export const supportCenterLink = (publicUrl) => [
  {
    key: "1",
    label: (
      <a
        href={`${publicUrl}/faq`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>FAQ</span>
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        href={`${publicUrl}/cart2cart-videos`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Video Tutorials</span>
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        href={`${publicUrl}/planned-shopping-carts`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Possible shopping carts</span>
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        href="https://support.magneticone.com/hc/en-us/requests/new?department=cart2cart"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Submit a Ticket</span>
      </a>
    ),
  },
  {
    key: "5",
    label: (
      <a
        href="https://ideas.shopping-cart-migration.com"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Submit a Feature</span>
      </a>
    ),
  },
  {
    key: "6",
    label: (
      <a
        href={`${publicUrl}/complaint-form`}
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Complaint Form</span>
      </a>
    ),
  },
];

export const adminIntegrationLink = [
  {
    key: "1",
    label: (
      <a
        href="https://magneticone.atlassian.net/jira/your-work"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Atlassian JIRA</span>
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        href="https://teamcity.magneticone.com:8111"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>JetBrains TeamCity</span>
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        href="https://bitbucket.org/"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Bitbucket</span>
      </a>
    ),
  },
];

export const adminSupportLink = [
  {
    key: "1",
    label: (
      <a
        href="https://supportone.zendesk.com/"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
      >
        <span>Zendesk</span>
      </a>
    ),
  },
];

export const adminQaLink = [
  {
    key: "1",
    label: (
      <Link to="/admin/automation" className="footerLink">
        <span>Create automated migration</span>
      </Link>
    ),
  },
];

const adminLinks = [
  {
    title: "Integration",
    items: adminIntegrationLink,
  },
  {
    title: "Support",
    items: adminSupportLink,
  },
  {
    title: "QA",
    items: adminQaLink,
  },
];

const userLinks = (publicUrl) => [
  {
    title: "Information",
    items: informationLink(publicUrl),
  },
  {
    title: "Support center",
    items: supportCenterLink(publicUrl),
  },
  {
    title: "For Partner",
    items: forPartnerLink(publicUrl),
  },
];

export const linksByPermission = (isPermission, publicUrl) => {
  if (isPermission) {
    return adminLinks;
  }

  return userLinks(publicUrl);
};
