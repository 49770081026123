import React, { useEffect } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useFormTo } from "../../hooks/useFormTo";
import { useValidateCartPairMutation } from "../../store/wizardCreate/wizardCreateService";
import {
  setFromTo,
  setFromToParam,
  setFirstFromTo
} from "../../store/wizardCreate/wizardCreateSlice";

import { getStoreFromRoute } from "../../utils";
import { PATTERN_FROM_TO_PARAMS } from "../../constants/params";
import { AUTH_PATH } from "../../router/path/auth";

import LoadingModal from "../../components/LoadingModal";

const FromToProvider = () => {
  const { fromTo } = useParams();
  const { user } = useAuth();
  const { pathname, hash } = useLocation();
  const { prefix } = useFormTo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validatePair, { isLoading }] = useValidateCartPairMutation();
  const handleValidation = async (values) => {
    dispatch(setFromToParam({ prefix: fromTo ?? prefix }));
    dispatch(setFirstFromTo(fromTo ?? prefix));
    try {
      const res = await validatePair({
        ...values,
      }).unwrap();

      const payload = res?.payload;

      if (payload && typeof payload === "object") {
        const stores = {
          source: "",
          target: "",
          preview: false,
        };

        Object.keys(payload).forEach((key) => {
          const value = payload[key];
          if (value) {
            stores[key] = values?.[key];
          }
        });

        let fromToPath = '';

        if (values?.source && stores?.source) {
          fromToPath += `from-${values?.source}`;
        }

        if (values?.target && stores?.target) {
          fromToPath += `${fromToPath ? '-' : ''  }to-${values?.target}`;
        }

        dispatch(setFromTo(stores));
        dispatch(setFromToParam({ prefix: fromToPath ?? prefix }));
        localStorage.setItem("source", stores?.source);
        localStorage.setItem("target", stores?.target);
      }
    } catch (error) {
      navigate("*");
    }
  };

  const fromToRules = (param) => {
    localStorage.setItem("source", "");
    localStorage.setItem("target", "");
    if (PATTERN_FROM_TO_PARAMS.test(param)) {
      if (user?.id) {
        handleValidation({
          source: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "source",
          ),
          target: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "target",
          ),
          preview: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "preview",
          ),
        });
      } else {
        dispatch(
          setFromToParam({ prefix: param, route: `${pathname}${hash}` }),
        );

        navigate(AUTH_PATH.LOGIN, {replace: true})
      }
    } else if (!user?.id) {
      dispatch(setFromToParam({ prefix: param, route: `${pathname}${hash}` }));
      navigate(AUTH_PATH.LOGIN, {replace: true})
    }
  };

  useEffect(() => {
    if (user?.id) {
      fromToRules(fromTo ?? prefix);
    }
  }, [user?.id]);

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      <Outlet />
    </>
  );
};

export default FromToProvider;
