import React from "react";

import { openChat } from "../../../utils/providers";
import { ReactComponent as SelectedIcon } from "../../../assets/images/SelectedIcon.svg";
import { ReactComponent as LifebuoyIcon } from "../../../assets/images/lifebuoy.svg";
import { ReactComponent as OrderStatusIcon } from "../../../assets/images/actions/orderStatus.svg";
import { CONTACT_US } from "../../../constants/link";
import { DATA_MIGRATION_PACKAGES_LINK } from "../../../modules/RefactoredWizard/utils/start-full";

import {
  StyledButton,
  Ul,
  ErrorNotice,
  StyledLink,
} from "./styles";

const SupportError = ({ faqLink, small }) => {
  const showChat = async () => {
    openChat();
  };

  return (
      <ErrorNotice small>
        <p>Our support agent is ONLINE and ready to assist you</p>
        {!small && (<StyledButton onClick={showChat}>Chat Now</StyledButton>)}
        <Ul>
          {faqLink && (
            <li>
              <SelectedIcon />
              Find out
              <StyledLink href={faqLink} target="_blank">
                more information
              </StyledLink>
              about this error
            </li>
          )}
          <li>
            <LifebuoyIcon />
            Contact our
            <StyledLink href={CONTACT_US} target="_blank">
              Support Team
            </StyledLink>
            for more information
          </li>
          <li>
            <OrderStatusIcon />
            <StyledLink href={DATA_MIGRATION_PACKAGES_LINK} target="_blank">
              Delegate us all the work.
            </StyledLink>
          </li>
        </Ul>
      </ErrorNotice>
  );
};

export default SupportError;
