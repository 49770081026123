import { BaseQueryParams } from "../baseQuery";

export const wizardService = BaseQueryParams("wizardService", [
  "Account_Selection",
  "MAPPING",
  "Select_Source",
  "MIGRATION_SESSION",
  "CHANGE_MIGRATION",
  "SURVEY",
  "DETAILS_MIGRATION",
  "RATE",
]).injectEndpoints({
  endpoints: (builder) => ({
    getEmails: builder.query({
      query: ({ email }) => ({
        url: `/migrations/wizard/get-accounts${
          email ? `?search=${encodeURIComponent(email)}` : ""
        }`,
        method: "GET",
      }),

      providesTags: () => ["Account_Selection"],
    }),

    getMigrationSession: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "GET",
      }),

      providesTags: () => ["MIGRATION_SESSION", "CHANGE_MIGRATION"],
    }),

    checkMigrationStatus: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "GET",
      }),
    }),

    getAvailableStores: builder.query({
      query: () => ({
        url: "/migrations/wizard/available-stores",
        method: "GET",
      }),

      providesTags: () => ["Select_Source"],
    }),

    setupStores: builder.mutation({
      query: (data) => ({
        url: "/migrations/wizard/stores-setup",
        method: "POST",
        body: data,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    cartFields: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/cart-fields",
        method: "POST",
        body,
      }),

      keepUnusedDataFor: 0,
      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    checkTypeConnect: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/cart-fields",
        method: "POST",
        body,
      }),

      keepUnusedDataFor: 0,
    }),

    downloadBridge: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/bridge",
        method: "GET",
      }),
    }),

    connectSourceBridge: builder.mutation({
      query: ({ url, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/bridge`,
        method: "POST",
        body: { url },
      }),
    }),

    connectFtp: builder.mutation({
      query: ({ data, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/ftp`,
        method: "POST",
        body: data,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    connectApi: builder.mutation({
      query: ({ data, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/api`,
        method: "POST",
        body: data,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    getEntities: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/entities-selection",
        method: "GET",
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    getOptions: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/options",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION", "DETAILS_MIGRATION"],
    }),

    getResources: builder.query({
      query: ({ entityQuery }) => ({
        url: `/migrations/wizard/resources?${entityQuery}save=1`,
        method: "GET",
      }),
    }),

    updateMigrationSession: builder.mutation({
      query: (migrationId) => ({
        url: `/migrations/wizard/migration/${migrationId}`,
        method: "PUT",
      }),

      invalidatesTags: () => ["CHANGE_MIGRATION"],
    }),

    continueMigrationSession: builder.mutation({
      query: (migrationId) => ({
        url: `/migrations/wizard/migration/${migrationId}`,
        method: "PUT",
        body: { reset: 0 },
      }),

      invalidatesTags: () => ["CHANGE_MIGRATION"],
    }),

    editMigrationSession: builder.mutation({
      query: (migrationId) => ({
        url: `/migrations/wizard/migration/${migrationId}`,
        method: "PUT",
      }),

      invalidatesTags: () => ["CHANGE_MIGRATION", "MIGRATION_SESSION"],
    }),

    createMigration: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "POST",
      }),

      invalidatesTags: () => ["CHANGE_MIGRATION", "MIGRATION_SESSION"],
    }),

    getMappingOption: builder.query({
      query: () => ({
        url: "/migrations/wizard/mapping",
        method: "GET",
      }),

      providesTags: () => ["MAPPING"],
    }),

    startDemoMigration: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/migration/demo",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    setMapping: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/mapping",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    getDemoProcess: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo-process",
        method: "GET",
      }),
    }),

    getMigrationConfig: builder.query({
      query: () => ({
        url: "/migrations/wizard/config",
        method: "GET",
      }),
    }),

    getPartnerEmails: builder.mutation({
      query: (account_email) => ({
        url: "/migrations/wizard/account-selection",
        method: "POST",
        body: { account_email },
      }),
    }),

    connectionApiStore: builder.mutation({
      query: ({ body, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/api`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    // DEMO
    startDemo: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/migration/demo",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    getSurvey: builder.query({
      query: () => ({
        url: "/migrations/wizard/survey",
        method: "GET",
      }),

      providesTags: () => ["SURVEY"],
    }),

    setSurvey: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/survey",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SURVEY"],
    }),

    getDemoProgress: builder.query({
      query: () => ({
        url: "/migrations/wizard/survey",
        method: "GET",
      }),
    }),

    getDemoProgressEntities: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo/entities",
        method: "GET",
      }),
    }),

    getDemoResult: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo/result",
        method: "GET",
      }),
    }),

    getDemoEntities: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo/entities",
        method: "GET",
      }),
    }),

    getDemoRate: builder.query({
      query: () => ({
        url: "/migrations/wizard/demo/rate",
        method: "GET",
      }),
      providesTags: () => ["RATE"],
    }),

    setDemoRate: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/demo/rate",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["RATE"],
    }),

    getInsurances: builder.query({
      query: () => ({
        url: "/migrations/wizard/insurances",
        method: "GET",
      }),
    }),

    getMigrationDetails: builder.query({
      query: ({ migration_id }) => ({
        url: `migrations/${migration_id}/details`,
        method: "GET",
      }),

      providesTags: () => ["DETAILS_MIGRATION"],
    }),

    sendInsurances: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/insurance",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    startFullMigration: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/start-full-migration",
        method: "POST",
        body: {
          rdb: 0,
        },
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    getFullTotals: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/full-migration",
        method: "POST",
      }),
    }),

    applyCoupon: builder.mutation({
      query: (code) => ({
        url: "/migrations/wizard/apply-coupon",
        method: "POST",
        body: { discount_coupon_code: code },
      }),

      invalidatesTags: () => ["MIGRATION_SESSION", "DETAILS_MIGRATION"],
    }),

    validateCartPair: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/cart-pairs/validate",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetEmailsQuery,
  useGetAvailableStoresQuery,
  useSetupStoresMutation,
  useCartFieldsMutation,
  useGetMigrationSessionQuery,
  useCheckTypeConnectMutation,
  useDownloadBridgeQuery,
  useConnectSourceBridgeMutation,
  useConnectFtpMutation,
  useConnectApiMutation,
  useGetEntitiesQuery,
  useGetOptionsMutation,
  useGetResourcesQuery,
  useUpdateMigrationSessionMutation,
  useCreateMigrationMutation,
  useGetMappingOptionQuery,
  useStartDemoMigrationMutation,
  useGetDemoProcessQuery,
  useGetMigrationConfigQuery,
  useSetMappingMutation,
  useGetPartnerEmailsMutation,
  useConnectionApiStoreMutation,
  useEditMigrationSessionMutation,
  useStartDemoMutation,
  useGetSurveyQuery,
  useGetDemoProgressQuery,
  useSetSurveyMutation,
  useGetDemoProgressEntitiesQuery,
  useCheckMigrationStatusQuery,
  useGetDemoResultQuery,
  useGetDemoEntitiesQuery,
  useGetDemoRateQuery,
  useSetDemoRateMutation,
  useContinueMigrationSessionMutation,
  useGetInsurancesQuery,
  useSendInsurancesMutation,
  useStartFullMigrationMutation,
  useGetFullTotalsMutation,
  useApplyCouponMutation,
  useGetMigrationDetailsQuery,
  useValidateCartPairMutation,
} = wizardService;
